import { useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "components/Tooltip/Tooltip";

import hexToRgba from "hex-to-rgba";
import { BigNumber, ethers } from "ethers";

import { useFees, useFeesSince, useSpreadCaptureVolume, useVolume } from "../../Api";

import {
  USD_DECIMALS,
  BSX_DECIMALS,
  BLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDM_AMOUNT,
  getPageTitle,
  importImage,
  arrayURLFetcher,
} from "lib/legacy";

import { fetcher } from "../../Helpers";

import { useTotalBsxInLiquidity, useBsxPrice, useTotalBsxStaked } from "domain/legacy";

import { getContract } from "config/contracts";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import BlpManager from "abis/BlpManager.json";
import Footer from "components/Footer/Footer";

import "./DashboardV2.css";

import AssetDropdown from "./AssetDropdown";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import { useFeesSummary, useVolumeInfo } from "domain/stats";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
//TODO: Add BASE to active chains
import { ARBITRUM, ARBITRUM_TESTNET, getChainName } from "config/chains";
import { getServerUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenBySymbol, getWhitelistedTokens, BLP_POOL_COLORS } from "config/tokens";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, numberWithCommas } from "lib/numbers";
import { useChainId } from "lib/chains";
import { formatDate } from "lib/dates";
import { getIcons } from "config/icons";
import { currentFortnight, SECONDS_PER_WEEK } from "domain/fees";

const ACTIVE_CHAIN_IDS = [ARBITRUM, ARBITRUM_TESTNET];

const { AddressZero } = ethers.constants;

function getPositionStats(positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null;
  }
  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes);
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes);
      acc[ACTIVE_CHAIN_IDS[i]] = cv;
      return acc;
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
    }
  );
}

//TODO: Get Current Fees
// function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
//   if (!fees || !infoTokens) {
//     return bigNumberify(0);
//   }

//   let currentFeesUsd = bigNumberify(0);
//   for (let i = 0; i < tokenAddresses.length; i++) {
//     const tokenAddress = tokenAddresses[i];
//     const tokenInfo = infoTokens[tokenAddress];
//     if (!tokenInfo || !tokenInfo.contractMinPrice) {
//       continue;
//     }

//     const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
//     currentFeesUsd = currentFeesUsd.add(feeUsd);
//   }

//   return currentFeesUsd;
// }

export const getUnclaimedFees = (tokenAddresses, infoTokens, fees) => {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
};

export default function DashboardV2() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const totalVolume = useVolume(chainId);

  const chainName = getChainName(chainId);
  const currentIcons = getIcons(chainId);

  const { data: positionStats } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/position_stats")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  //TODO: Use the useTotalBSXSupply hook
  let { total: totalBsxSupply } = { total: BigNumber.from("1000000000000000000000000") };

  //TODO: Extend to Base and BaseGoerli
  //let { circulating: circulatingBSXSupply } = useTotalBSXSupply();

  //let { circulating: circulatingBSXSupply } = {total: BigNumber.from('1000000000000000000000000')};

  const currentVolumeInfo = useVolumeInfo();

  const positionStatsInfo = getPositionStats(positionStats);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const blpManagerAddress = getContract(chainId, "BlpManager");

  const bsxAddress = getContract(chainId, "BSX");
  const blpAddress = getContract(chainId, "BLP");
  const usdmAddress = getContract(chainId, "USDM");

  const tokensForSupplyQuery = [bsxAddress, blpAddress, usdmAddress];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, blpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, BlpManager),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`BlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, Vault),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const eth = infoTokens[getTokenBySymbol(chainId, "ETH").address];

  const { data: feesSummaryByChain } = useFeesSummary(chainId);
  const feesSummary = feesSummaryByChain[chainId];

  const { data: fees } = useSWR([`Dashboard:fees:${active}`, chainId, readerAddress, "getFees", vaultAddress], {
    fetcher: fetcher(library, ReaderV2, [whitelistedTokens]),
  });

  const allFees = useFees(chainId);

  // const feeHistory = getFeeHistory(chainId);

  const from = currentFortnight;
  // feeHistory[0]?.to;
  const to = from + SECONDS_PER_WEEK * 2;
  const currentGraphFees = useFeesSince(chainId, from, to);
  const currentUnclaimedFees = getUnclaimedFees(whitelistedTokens, infoTokens, fees);
  let totalCurrentFees;
  if (currentUnclaimedFees && currentGraphFees) {
    totalCurrentFees = currentUnclaimedFees.gt(currentGraphFees) ? currentUnclaimedFees : currentGraphFees;
  }

  let totalFeesDistributed;
  if (allFees) {
    totalFeesDistributed = bigNumberify(allFees.mint)
      .add(allFees.burn)
      .add(allFees.marginAndLiquidation)
      .add(allFees.swap);
  }

  const totalMMFees = useSpreadCaptureVolume(chainId);

  let totalFees;
  totalFees = totalFeesDistributed;
  if (totalFeesDistributed && totalMMFees) {
    totalFees = totalFeesDistributed.add(totalMMFees);
  }

  //TODO: Get the price either from Arbitrum or Base Mainnet in the future
  const { bsxPrice, bsxPriceFromArbitrum } = useBsxPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined },
    // {base: chainId === BASE ? library : undefined},
    active
  );

  //TODO: Extend to Base and BaseGoerli
  let {
    total: totalBsxInLiquidity,
    // arbitrum: metawinInLiquidityOnArbitrum,
    // testnet: metawinInLiquidityOnTestnet,
    // base: metawinInLiquidityOnBase,
    // baseGoerli: metawinInLiquidityOnBaseGoerli,
  } = useTotalBsxInLiquidity(chainId, active);

  //For Mainnet only

  //let { arbitrum: arbitrumStakedBsx, total: totalStakedBsx } = useTotalBsxStaked();

  let { testnet: testnetStakedBsx, total: totalStakedBsx } = useTotalBsxStaked();

  let bsxFullyDilutedMarketCap;
  if (bsxPrice && totalBsxSupply) {
    bsxFullyDilutedMarketCap = bsxPrice.mul(totalBsxSupply).div(expandDecimals(1, BSX_DECIMALS));
  }

  let stakedBsxSupplyUsd;
  if (bsxPrice && totalStakedBsx) {
    stakedBsxSupplyUsd = totalStakedBsx.mul(bsxPrice).div(expandDecimals(1, BSX_DECIMALS));
  }

  let bsxMarketCap;
  if (bsxPrice && totalBsxSupply) {
    bsxMarketCap = bsxPrice.mul(totalBsxSupply).div(expandDecimals(1, BSX_DECIMALS));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let blpPrice;
  let blpSupply;
  let blpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    blpSupply = totalSupplies[3];
    blpPrice =
      aum && aum.gt(0) && blpSupply.gt(0)
        ? aum.mul(expandDecimals(1, BLP_DECIMALS)).div(blpSupply)
        : expandDecimals(1, USD_DECIMALS);
    blpMarketCap = blpPrice.mul(blpSupply).div(expandDecimals(1, BLP_DECIMALS));
  }

  let tvl;
  if (blpMarketCap && bsxPrice && totalStakedBsx) {
    tvl = blpMarketCap.add(bsxPrice.mul(totalStakedBsx).div(expandDecimals(1, BSX_DECIMALS)));
  }

  // We don't currently have a  price floor fund, so we cannot account for this
  const ethFloorPriceFund = expandDecimals(0, 18);
  const blpFloorPriceFund = expandDecimals(0, 18);
  const usdcFloorPriceFund = expandDecimals(0, 30);

  let totalFloorPriceFundUsd;

  if (eth && eth.contractMinPrice && blpPrice) {
    const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
    const blpFloorPriceFundUsd = blpFloorPriceFund.mul(blpPrice).div(expandDecimals(1, 18));

    totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(blpFloorPriceFundUsd).add(usdcFloorPriceFund);
  }

  let adjustedUsdmSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdmAmount) {
      adjustedUsdmSupply = adjustedUsdmSupply.add(tokenInfo.usdmAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdmAmount ||
      !adjustedUsdmSupply ||
      adjustedUsdmSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdmAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdmSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={t`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is below its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/buy_blp" target="_blank" rel="noopener noreferrer">
                      buy BLP
                    </Link>{" "}
                    with {tokenInfo.symbol}, and to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    {tokenInfo.symbol} for other tokens.
                  </Trans>
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div className="text-white">
                  <Trans>
                    {tokenInfo.symbol} is above its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{" "}
                    tokens for {tokenInfo.symbol}.
                  </Trans>
                </div>
              )}
              <br />
              <div>
                <ExternalLink href="https://bsxio.gitbook.io/bsx/blp">
                  <Trans>More Info</Trans>
                </ExternalLink>
              </div>
            </>
          );
        }}
      />
    );
  };

  let stakedPercent = 0;

  if (totalBsxSupply && !totalBsxSupply.isZero() && !totalStakedBsx.isZero()) {
    stakedPercent = totalStakedBsx.mul(100).div(totalBsxSupply).toNumber();
  }

  // let arbitrumStakedPercent = 0
  // if (circulatingBSXSupply && !circulatingBSXSupply.isZero() && arbitrumStakedPercent) {
  //   arbitrumStakedPercent = equaliseValue(useStakedBsxSupply) / equaliseValue(circulatingBSXSupply);
  //   arbitrumStakedPercent = formatPercentage(arbitrumStakedPercent);
  // }

  let liquidityPercent = 0;

  if (totalBsxSupply && !totalBsxSupply.isZero() && totalBsxInLiquidity) {
    liquidityPercent = totalBsxInLiquidity.mul(100).div(totalBsxSupply).toNumber();
  }

  //TODO: Chain specific stats - Allow users to change from total to {chainId} Stats
  // let arbitrumLiquidityPercent = 0;
  // if (circulatingBSXSupply && !circulatingBSXSupply.isZero() && metawinInLiquidityOnArbitrum) {
  //   arbitrumLiquidityPercent = equaliseValue(metawinInLiquidityOnArbitrum) / equaliseValue(circulatingBSXSupply);
  //   arbitrumLiquidityPercent = formatPercentage(arbitrumLiquidityPercent);
  // }

  let notStakedPercent = 100 - stakedPercent - liquidityPercent;

  let bsxDistributionData = [
    {
      name: t`staked`,
      value: stakedPercent,
      color: "#4353fa",
    },
    {
      name: t`in liquidity`,
      value: liquidityPercent,
      color: "#0598fa",
    },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#5c0af5",
    },
  ];

  const totalStatsStartDate = t`17 May 2023`;

  let stableBlp = 0;
  let totalBlp = 0;

  let blpPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdmAmount && adjustedUsdmSupply && adjustedUsdmSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdmAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdmSupply);
      if (tokenInfo.isStable) {
        stableBlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalBlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      };
    }
    return null;
  });

  let stablePercentage = totalBlp > 0 ? ((stableBlp * 100) / totalBlp).toFixed(2) : "0.0";

  blpPool = blpPool.filter(function (element) {
    return element !== null;
  });

  blpPool = blpPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  bsxDistributionData = bsxDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [bsxActiveIndex, setBSXActiveIndex] = useState(null);

  const onBSXDistributionChartEnter = (_, index) => {
    setBSXActiveIndex(index);
  };

  const onBSXDistributionChartLeave = (_, index) => {
    setBSXActiveIndex(null);
  };

  const [blpActiveIndex, setBLPActiveIndex] = useState(null);

  const onBLPPoolChartEnter = (_, index) => {
    setBLPActiveIndex(index);
  };

  const onBLPPoolChartLeave = (_, index) => {
    setBLPActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="default-container DashboardV2 page-layout">
        <div className="section-title-block">
          <div className="section-title-icon"></div>
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Stats</Trans> <img width="24" src={currentIcons.network} alt="Network Icon" />
            </div>
            <div className="Page-description">
              <Trans>
                {chainName} Total Stats start from {totalStatsStartDate}.<br /> For detailed stats:
              </Trans>{" "}
              {chainId === ARBITRUM && (
                <ExternalLink href="https://stats.pro.metawin.com">https://stats.pro.metawin.com</ExternalLink>
              )}
            </div>
          </div>
        </div>
        <div className="DashboardV2-content">
          <div className="DashboardV2-cards">
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Overview</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>AUM</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => (
                        <span>{t`Assets Under Management: BSX staked (All chains) + BLP pool (${chainName}).`}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>BLP Pool</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => (
                        <Trans>
                          <p>Total value of tokens in BLP pool ({chainName}).</p>
                          <p>
                            Other websites may show a higher value as they add positions' collaterals to the BLP pool.
                          </p>
                        </Trans>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>24h Volume</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(currentVolumeInfo?.[chainId], USD_DECIMALS, 0, true)}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Volume`}
                          arbitrumValue={currentVolumeInfo?.[ARBITRUM]}
                          total={currentVolumeInfo?.total}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Long Positions</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalLongPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Long Positions`}
                          arbitrumValue={positionStatsInfo?.[ARBITRUM].totalLongPositionSizes}
                          total={positionStatsInfo?.totalLongPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Short Positions</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(
                        positionStatsInfo?.[chainId]?.totalShortPositionSizes,
                        USD_DECIMALS,
                        0,
                        true
                      )}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Short Positions`}
                          arbitrumValue={positionStatsInfo?.[ARBITRUM].totalShortPositionSizes}
                          total={positionStatsInfo?.totalShortPositionSizes}
                        />
                      )}
                    />
                  </div>
                </div>
                {feesSummary?.lastUpdatedAt ? (
                  <div className="App-card-row">
                    <div className="label">
                      <Trans>Fees since</Trans> {formatDate(feesSummary.lastUpdatedAt)}
                    </div>
                    <div>
                      <TooltipComponent
                        position="right-bottom"
                        className="nowrap"
                        handle={`$${formatAmount(totalCurrentFees, USD_DECIMALS, 2, true)}`}
                        renderContent={() => <StatsTooltip title={t`Fees`} total={totalCurrentFees} />}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title">
                <Trans>Total Stats</Trans>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Total Fees</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${numberWithCommas(totalFees?.[chainId])}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Total Fees`}
                          arbitrumValue={totalFees?.[ARBITRUM]}
                          total={totalFees?.total}
                          decimalsForConversion={0}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Total Volume</Trans>
                  </div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(totalVolume?.[chainId], USD_DECIMALS, 0, true)}`}
                      renderContent={() => (
                        <StatsTooltip
                          title={t`Total Volume`}
                          arbitrumValue={totalVolume?.[ARBITRUM]}
                          total={totalVolume?.total}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <Trans>Floor Price Fund</Trans>
                  </div>
                  <div>${formatAmount(totalFloorPriceFundUsd, 30, 0, true)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tab-title-section">
            <div className="Page-title">
              <Trans>Tokens</Trans> <img src={currentIcons.network} width="24" alt="Network Icon" />
            </div>
            <div className="Page-description">
              <Trans>Platform and BLP index tokens.</Trans>
            </div>
          </div>
          <div className="DashboardV2-token-cards">
            <div className="stats-wrapper stats-wrapper--bsx">
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={currentIcons.bsx} width="40" alt="BSX Token Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">BSX</div>
                        <div className="App-card-title-mark-subtitle">BSX</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="BSX" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>
                        {!bsxPrice && "..."}
                        {bsxPrice && (
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={"$" + formatAmount(bsxPrice, USD_DECIMALS, 2, true)}
                            renderContent={() => (
                              <>
                                <StatsTooltipRow
                                  label={t`Price on Arbitrum`}
                                  value={formatAmount(bsxPriceFromArbitrum, USD_DECIMALS, 2, true)} //TODO: We will need to get the price from Base Mainnet in the future
                                  showDollar={true}
                                />
                              </>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(totalBsxSupply, BSX_DECIMALS, 0, true)} BSX</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>
                        <TooltipComponent
                          position="right-bottom"
                          className="nowrap"
                          handle={`$${formatAmount(stakedBsxSupplyUsd, USD_DECIMALS, 0, true)}`}
                          renderContent={() => (
                            <StatsTooltip
                              title={t`Staked`}
                              //arbitrumValue={arbitrumStakedBsx}
                              testnetValue={testnetStakedBsx}
                              total={totalStakedBsx}
                              decimalsForConversion={BSX_DECIMALS}
                              showDollar={false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(bsxMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>FDV Market Cap</Trans>
                      </div>
                      <div>${formatAmount(bsxFullyDilutedMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseLeave={onBSXDistributionChartLeave}>
                  {bsxDistributionData.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={bsxDistributionData}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={2}
                        onMouseEnter={onBSXDistributionChartEnter}
                        onMouseOut={onBSXDistributionChartLeave}
                        onMouseLeave={onBSXDistributionChartLeave}
                      >
                        {bsxDistributionData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                bsxActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={bsxActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        <Trans>Distribution</Trans>
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={currentIcons.blp} width="40" alt="BLP Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">BLP</div>
                        <div className="App-card-title-mark-subtitle">BLP</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="BLP" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>${formatAmount(blpPrice, USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Supply</Trans>
                      </div>
                      <div>{formatAmount(blpSupply, BLP_DECIMALS, 0, true)} BLP</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>${formatAmount(blpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div>${formatAmount(blpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Stablecoin Percentage</Trans>
                      </div>
                      <div>{stablePercentage}%</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseOut={onBLPPoolChartLeave}>
                  {blpPool.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={blpPool}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        onMouseEnter={onBLPPoolChartEnter}
                        onMouseOut={onBLPPoolChartLeave}
                        onMouseLeave={onBLPPoolChartLeave}
                        paddingAngle={2}
                      >
                        {blpPool.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={BLP_POOL_COLORS[entry.name]}
                            style={{
                              filter:
                                blpActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(BLP_POOL_COLORS[entry.name], 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={BLP_POOL_COLORS[entry.name]}
                            strokeWidth={blpActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        BLP Pool
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
            </div>
            <div className="token-table-wrapper App-card">
              <div className="App-card-title">
                <Trans>BLP Index Composition</Trans> <img src={currentIcons.network} width="16" alt="Network Icon" />
              </div>
              <div className="App-card-divider"></div>
              <table className="token-table">
                <thead>
                  <tr>
                    <th>
                      <Trans>TOKEN</Trans>
                    </th>
                    <th>
                      <Trans>PRICE</Trans>
                    </th>
                    <th>
                      <Trans>POOL</Trans>
                    </th>
                    <th>
                      <Trans>WEIGHT</Trans>
                    </th>
                    <th>
                      <Trans>UTILIZATION</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {visibleTokens.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    let maxUsdmAmount = DEFAULT_MAX_USDM_AMOUNT;
                    if (tokenInfo.maxUsdmAmount && tokenInfo.maxUsdmAmount.gt(0)) {
                      maxUsdmAmount = tokenInfo.maxUsdmAmount;
                    }
                    const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage} alt={token.symbol} width="40" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            className="nowrap"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdmAmount, 18, 0, true)}
                                    showDollar={true}
                                  />
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="token-grid">
              {visibleTokens.map((token) => {
                const tokenInfo = infoTokens[token.address];
                let utilization = bigNumberify(0);
                if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                  utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                }
                let maxUsdmAmount = DEFAULT_MAX_USDM_AMOUNT;
                if (tokenInfo.maxUsdmAmount && tokenInfo.maxUsdmAmount.gt(0)) {
                  maxUsdmAmount = tokenInfo.maxUsdmAmount;
                }

                const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div className="mobile-token-card">
                        <img src={tokenImage} alt={token.symbol} width="20px" />
                        <div className="token-symbol-text">{token.symbol}</div>
                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Price</Trans>
                        </div>
                        <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Pool</Trans>
                        </div>
                        <div>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  <StatsTooltipRow
                                    label={t`Pool Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Target Min Amount`}
                                    value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                      token.symbol
                                    }`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label={t`Max ${tokenInfo.symbol} Capacity`}
                                    value={formatAmount(maxUsdmAmount, 18, 0, true)}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Weight</Trans>
                        </div>
                        <div>{getWeightText(tokenInfo)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">
                          <Trans>Utilization</Trans>
                        </div>
                        <div>{formatAmount(utilization, 2, 2, false)}%</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
