import { ARBITRUM, ARBITRUM_TESTNET, BASE, BASE_GOERLI } from "config/chains";
import arbitrum from "img/ic_arbitrum_24.svg";
//import base from "img/ic_base_24.svg";

import bsxIcon from "img/ic_bsx_40.svg";
import blpIcon from "img/ic_blp_40.svg";
import bsxArbitrum from "img/ic_bsx_arbitrum.svg";
import blpArbitrum from "img/ic_blp_arbitrum.svg";

const ICONS = {
  [ARBITRUM]: {
    network: arbitrum,
    bsx: bsxArbitrum,
    blp: blpArbitrum,
  },
  [ARBITRUM_TESTNET]: {
    network: arbitrum,
  },
  [BASE]: {
    network: arbitrum,
  },
  [BASE_GOERLI]: {
    network: arbitrum,
  },
  common: {
    bsx: bsxIcon,
    blp: blpIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
