import { ApolloClient, InMemoryCache } from "@apollo/client";

//TODO: Use the chainId as the key for the object of each graph client
export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/deividask/chainlink");
export const arbitrumGraphClient = createClient(
  "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-stats/api"
);

export const arbitrumTestnetGraphClient = createClient(
  "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-stats/api"
);
export const arbitrumGoerliVaultGraphClient = createClient(
  "https://subgraph.satsuma-prod.com/metawin/metawin-base-goerli-vault/version/master-230929221047-1b48474/api"
);
export const arbitrumReferralsGraphClient = createClient(
  "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-referrals/api" //TODO: Change this to the correct subgraph
);
export const arbitrumTestnetReferralsGraphClient = createClient(
  "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-referrals/api"
);

function createClient(uri: string) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}
