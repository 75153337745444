import React, { useCallback } from "react";
import Footer from "components/Footer/Footer";
import "./BuyBSX.css";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import Button from "components/Button/Button";
import { ARBITRUM, getChainName, getConstant } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import Card from "components/Common/Card";
import { importImage } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";

import uniswapArbitrumIcon from "img/ic_uni_arbitrum.svg";
import {
  BUY_NATIVE_TOKENS,
  DECENTRALISED_AGGREGATORS,
  EXTERNAL_LINKS,
  FIAT_GATEWAYS,
  BSX_FROM_ANY_NETWORKS,
  TRANSFER_EXCHANGES,
} from "./constants";

export default function BuyBSX() {
  const { chainId } = useChainId();
  const { active } = useWeb3React();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const externalLinks = EXTERNAL_LINKS[chainId];

  const onNetworkSelect = useCallback(
    (value) => {
      if (value === chainId) {
        return;
      }
      return switchNetwork(value, active);
    },
    [chainId, active]
  );

  return (
    <div className="BuyBSXBLP default-container page-layout">
      <div className="BuyBSXBLP-container">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Buy BSX on {getChainName(chainId)}</Trans>
            </div>
            <div className="Page-description">
              <Trans>Choose to buy from decentralized or centralized exchanges.</Trans>
              <br />
              <Trans>
                To purchase BSX on the {"Arbitrum"} blockchain, please{" "}
                <span onClick={() => onNetworkSelect(ARBITRUM)}>change your network</span>.
              </Trans>
            </div>
          </div>
        </div>
        <div className="cards-row">
          <DecentralisedExchanges chainId={chainId} externalLinks={externalLinks} />
          <CentralisedExchanges chainId={chainId} />
        </div>
        <div className="section-title-block mt-top">
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Buy or Transfer ETH to Arbitrum</Trans>
            </div>
            <div className="Page-description">
              <Trans>Buy ETH directly to Arbitrum or transfer it there.</Trans>
            </div>
          </div>
        </div>

        <div className="cards-row">
          <Card title={t`Buy ${nativeTokenSymbol}`}>
            <div className="App-card-content">
              <div className="BuyBSXBLP-description">
                <Trans>
                  You can buy ETH directly on <ExternalLink href={externalLinks.networkWebsite}>Arbitrum</ExternalLink>{" "}
                  using these options:
                </Trans>
              </div>
              <div className="buttons-group">
                {BUY_NATIVE_TOKENS.filter((e) => chainId in e.links).map((exchange) => {
                  const icon = importImage(exchange.icon) || "";
                  const link = exchange.links[chainId];
                  return (
                    <Button
                      variant="clear"
                      key={exchange.name}
                      to={link}
                      imgInfo={{ src: icon, alt: exchange.name }}
                      newTab
                    >
                      {exchange.name}
                    </Button>
                  );
                })}
              </div>
            </div>
          </Card>
          <Card title={t`Transfer ${nativeTokenSymbol}`}>
            <div className="App-card-content">
              <div className="BuyBSXBLP-description">
                <Trans>You can transfer ETH from other networks to Arbitrum using any of the below options:</Trans>
              </div>

              <div className="buttons-group">
                {TRANSFER_EXCHANGES.filter((e) => chainId in e.links).map((exchange) => {
                  const icon = importImage(exchange.icon) || "";
                  const link = exchange.links[chainId];
                  return (
                    <Button
                      variant="clear"
                      key={exchange.name}
                      to={link}
                      imgInfo={{ src: icon, alt: exchange.name }}
                      newTab
                    >
                      {exchange.name}
                    </Button>
                  );
                })}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function DecentralisedExchanges({ chainId, externalLinks }) {
  return (
    <Card title={t`Buy BSX from a Decentralized Exchange`}>
      <div className="App-card-content">
        <div className="exchange-info-group">
          <div className="BuyBSXBLP-description">
            <Trans>Buy BSX from Uniswap (make sure to select Arbitrum):</Trans>
          </div>
          <div className="buttons-group col-1">
            <Button
              variant="clear"
              imgInfo={{ src: uniswapArbitrumIcon, alt: "Uniswap" }}
              to={externalLinks.buyBsx.uniswap}
              newTab
            >
              Uniswap
            </Button>
          </div>
        </div>
        <div className="exchange-info-group">
          <div className="BuyBSXBLP-description">
            <Trans>Buy BSX using Decentralized Exchange Aggregators:</Trans>
          </div>
          <div className="buttons-group">
            {DECENTRALISED_AGGREGATORS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links[chainId];
              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="exchange-info-group">
          <div className="BuyBSXBLP-description">
            <Trans>Buy BSX using any token from any network:</Trans>
          </div>
          <div className="buttons-group">
            {BSX_FROM_ANY_NETWORKS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links[chainId];
              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}

function CentralisedExchanges({ chainId }) {
  return (
    <Card title={t`Buy BSX from centralized services`}>
      <div className="App-card-content">
        <div className="exchange-info-group">
          <div className="BuyBSXBLP-description">
            <Trans>Buy BSX using FIAT gateways:</Trans>
          </div>
          <div className="buttons-group col-2">
            {FIAT_GATEWAYS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              let link = exchange.links[chainId];

              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
