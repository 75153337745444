import { createClient } from "./utils";
import { ARBITRUM, ARBITRUM_TESTNET, ETH_MAINNET, BASE, BASE_GOERLI } from "config/chains";

export const chainlinkClient = createClient(ETH_MAINNET, "chainLink");

//Don't export - Use the getter - getBsx<Network><Type>GraphClient
const arbitrumGraphClient = createClient(ARBITRUM, "stats");
export const arbitrumReferralsGraphClient = createClient(ARBITRUM, "referrals");
export const arbitrumVaultGraphClient = createClient(ARBITRUM, "vault");

export const arbitrumGoerliGraphClient = createClient(ARBITRUM_TESTNET, "stats");
export const arbitrumGoerliRefrralsGraphClient = createClient(ARBITRUM_TESTNET, "referrals");
export const arbitrumGoerliVaultGraphClient = createClient(ARBITRUM_TESTNET, "vault");

//TODO: Add remaining Base Graph Clients - Deployments required
export const baseGraphClient = createClient(BASE, "stats");
export const baseReferralsGraphClient = createClient(BASE, "referrals");
export const baseVaultGraphClient = createClient(BASE, "vault");

export const baseGoerliGraphClient = createClient(BASE_GOERLI, "stats");
export const baseGoerliReferralsGraphClient = createClient(BASE_GOERLI, "referrals");
export const baseGoerliVaultGraphClient = createClient(BASE_GOERLI, "vault");

export function getBsxStatsGraphClient(chainId: number) {
  switch (chainId) {
    case ARBITRUM: {
      return arbitrumGraphClient;
    }
    case ARBITRUM_TESTNET: {
      return arbitrumGoerliGraphClient;
    }
    case BASE: {
      return baseGraphClient;
    }
    case BASE_GOERLI: {
      return baseGoerliGraphClient;
    }
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

export function getBsxReferralsGraphClient(chainId: number) {
  switch (chainId) {
    case ARBITRUM: {
      return arbitrumReferralsGraphClient;
    }
    case ARBITRUM_TESTNET: {
      return arbitrumGoerliRefrralsGraphClient;
    }
    case BASE: {
      return baseReferralsGraphClient;
    }
    case BASE_GOERLI: {
      return baseGoerliReferralsGraphClient;
    }
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

export function getBsxVaultGraphClient(chainId: number) {
  switch (chainId) {
    case ARBITRUM: {
      return arbitrumVaultGraphClient;
    }
    case ARBITRUM_TESTNET: {
      return arbitrumGoerliVaultGraphClient;
    }
    case BASE: {
      return baseVaultGraphClient;
    }
    case BASE_GOERLI: {
      return baseGoerliVaultGraphClient;
    }
  }
  throw new Error(`Unsupported chain ${chainId}`);
}
