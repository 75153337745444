import React from "react";
import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import "./TermsAndConditions.css";

export default function TermsAndConditions() {
  return (
    <SEO title={getPageTitle("Terms and Conditions")}>
      <div className="default-container Page page-layout">
        <div>
          <div className="Page-title-section center">
            <div className="Page-title">BSX</div>
            <div className="Page-subtitle">Terms and Conditions</div>
            <div className="Page-description">Last modified: August 1st, 2022</div>
          </div>
          <div className="content">
            <div className="section">
              <p className="body-text">
                Welcome to pro.metawin.com ("we," "us," or "our"), the informational resource for Bsx Pro Platform, as
                defined below.
              </p>
              <p className="body-text">
                pro.metawin.com provides information and resources about the fundamentals of the decentralized
                non-custodial protocol called the Bsx Pro Platform (the "Bsx Pro Platform," "Protocol," or "BSX DApp").
                pro.metawin.com is not an available access point to the Bsx Pro Platform.
              </p>
              <p className="body-text">
                These Terms and Conditions and any other documents incorporated herein by reference (collectively, these
                "Terms") to you or the company or other legal entity you represent ("you" or "your") explains the terms
                and conditions by which you may access pro.metawin.com ("the Site") and app.pro.metawin.com ("the
                Interface"). Please do not use the Site or Interface if you disagree with any of these Terms.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">1. USE OF THE SITE AND THE INTERFACE</h3>
              <p className="body-text">The pro.metawin.com site is for informational purposes only.</p>
              <p className="body-text">
                pro.metawin.com is not part of any transaction on the blockchain networks underlying the Bsx Pro
                Platform; we do not have possession, custody, or control over any crypto assets appearing on the
                Interface; and we do not have possession, custody, or control over any user's funds. Further, we do not
                store, send, or receive any crypto assets. You understand that when you interact with any Bsx Pro
                Platform smart contracts, you always retain control over your crypto assets. We do not have access to
                your private keys.
              </p>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    1.1. As a condition to accessing or using the Site or Interface, you represent and warrant to
                    pro.metawin.com the following:
                  </p>
                  <ul className="list-style-none">
                    <li>
                      <p className="body-text">
                        1.1.1. If you are an individual person, then you are of legal age in the jurisdiction in which
                        you reside, and you have the legal capacity to enter into these Terms and be bound by them;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.2. If you are an entity, then you must have the legal authority to accept these Terms on
                        that entity's behalf, in which case "you" (except as used in this paragraph) will mean that
                        entity;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">1.1.3. You are not a U.S. Person;</p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.4. You are not a resident, national, or agent of any country to which the United States, the
                        United Kingdom, or the European Union embargoes goods or imposes similar sanctions
                        (collectively, "Restricted Territories");
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.5. You are not subject to economic or trade sanctions administered or enforced by any
                        governmental authority; or otherwise, you are not a member of any sanctions list or equivalent
                        maintained by the United States government, the United Kingdom government, the European Union,
                        or the United Nations, including without limitation the U.S. Office of Foreign Asset Control
                        Specifically Designated Nationals and Blocked Person List (collectively, "Sanctions Lists
                        Persons");
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.6. You do intend to transact with any Restricted Person or Sanctions List Person;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.7. You do not, and will not, use VPN software or any other privacy or anonymization tools or
                        techniques, or other means, to circumvent, or attempt to circumvent, any restrictions that
                        apply; and
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.1.8. Your access is not (a) prohibited by and does not otherwise violate or assist you in
                        violating any domestic or foreign law, rule, statute, regulation, by-law, order, protocol, code,
                        decree, letter, or another directive, requirement, guidance, or guideline, published or in force
                        that applies to or is otherwise intended to govern or regulate any person, property,
                        transaction, activity, event or other matter, including any rule, letter, order, judgment,
                        directive or other requirements, guidance, or guideline issued by any domestic or foreign
                        federal, provincial or state, municipal, local or other governmental, regulatory, judicial or
                        administrative authority having jurisdiction over pro.metawin.com or you as otherwise duly
                        enacted, enforceable by law, the common law or equity (collectively, "Applicable Laws"); or (b)
                        contribute to or facilitate any illegal activity.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    1.2. As a condition to accessing or using the Site or the Interface, you acknowledge, understand,
                    and agree to the following:
                  </p>
                  <ul className="list-style-none">
                    <li>
                      <p className="body-text">
                        1.2.1. From time to time, the Site or the Interface may be inaccessible or inoperable for any
                        reason, including, but not limited to: (a) equipment malfunctions; (b) periodic maintenance
                        procedures or repairs that pro.metawin.com or any of its suppliers or contractors may undertake
                        from time to time; (c) causes beyond pro.metawin.com's control or that pro.metawin.com could not
                        reasonably foresee; (d) disruptions and temporary or permanent unavailability of underlying
                        blockchain infrastructure; or (e) unavailability of third-party service providers or external
                        partners for any reason;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.2. We reserve the right to disable or modify access to the Site at any time in the event of
                        any breach of these Terms, including, without limitation, if we reasonably believe any of your
                        representations and warranties may be untrue or inaccurate, and we will not be liable to you for
                        any losses or damages you may suffer as a result of or in connection with the Site or the
                        Interface being inaccessible to you at any time or for any reason;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.3. The Interface may evolve, which means third parties may apply changes, replace, or
                        discontinue (temporarily or permanently) the access at any time in their sole discretion;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.4. The pricing information provided on the Site or the Interface does not represent an
                        offer, a solicitation of an offer, or any advice regarding, or recommendation to enter into, a
                        transaction with pro.metawin.com;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">1.2.5. pro.metawin.com does not act as a broker or advisor for you;</p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.6. You are solely responsible for your use of the Site or the Interface, including all of
                        your transfers of digital assets;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.7. To the fullest not prohibited by Applicable Law, we owe no fiduciary duties or
                        liabilities to you or any other party, and that to the extent any such duties or liabilities may
                        exist at law or in equity, you hereby irrevocably disclaim, waive, and eliminate those duties
                        and liabilities;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.8. You are solely responsible for reporting and paying any taxes applicable to your use of
                        the Interface; and
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        1.2.9. We have no control over, or liability for, the delivery, quality, safety, legality, or
                        any other aspect of any digital assets that you may transfer to or from a third party, and we
                        are not responsible for ensuring that an entity with whom you transact completes the transaction
                        or is authorized to do so. If you experience a problem with any transactions in digital assets
                        using the Site or the Interface, you bear the entire risk.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">2. FEES</h3>
              <p className="body-text">
                You are required to pay all fees for transactions involving certain blockchain networks. These fees may
                include gas costs and all other fees reflected on the Interface at your use, including trading-related
                fees. pro.metawin.com does not receive fees for any blockchain transactions or using the Site or the
                Interface.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">3. NO PROFESSIONAL ADVICE OR FIDUCIARY DUTIES</h3>
              <p className="body-text">
                Nothing herein constitutes legal, financial, business, or tax advice, and you are strongly advised to
                consult an advisor(s) before engaging in any activity in connection herewith. All information provided
                by the Site is for informational purposes only and should not be construed as professional advice. You
                should not take, or refrain from taking, any action based on any information contained on the Site or
                any other information that we make available at any time, including, without limitation, blog posts,
                articles, links to third-party content, discord content, news feeds, tutorials, tweets, and videos. The
                Terms are not intended to, and do not, create or impose any fiduciary duties on us.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">4. PROPRIETARY RIGHT</h3>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    4.1. pro.metawin.com own all rights, names, logos, and other marks used on the Site and the
                    Interface, including, without limitation, any copyrights in and to any content, code, data, or other
                    materials that you may access or use on or through the Site or the Interface; however, the code for
                    the BSX Protocol and the Interface (app.pro.metawin.com) deployed on IPFS is open-sourced. Except as
                    expressly set forth herein, your use of or access to the Site or the Interface does not grant you
                    any ownership or other rights therein.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    4.2. pro.metawin.com may use and share your comments, bug reports, ideas, or other feedback that you
                    may provide, including suggestions about how we might improve. You agree that pro.metawin.com is
                    free to use or not use any feedback we receive from you as we see fit, including copying and sharing
                    such feedback with third parties, without any obligation to you.
                  </p>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">5. MODIFICATION, SUSPENSION, AND TERMINATION</h3>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    5.1. pro.metawin.com reserve the right, at our sole discretion, from time to time and with or
                    without prior notice to you, to modify, suspend or disable (temporarily or permanently) the Site or
                    our subdomain to the Interface, in whole or in part, for any reason whatsoever, including, without
                    limitation. Upon termination of your access, your right to use the Site or the Interface from our
                    subdomain will immediately cease. However, it would still be accessible via a third party since we
                    do not host or own its code. pro.metawin.com will not be liable for any losses suffered by you
                    resulting from any modification to the Site or the Interface or from any modification, suspension,
                    or termination, for any reason, of your access to all or any portion of the Site or the Interface.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    5.2. pro.metawin.com may revise these Terms from time to time. We will notify you by updating the
                    date at the top of the Terms and maintaining a current version. The most current version of the
                    Terms will always be at https://pro.metawin.com/#/terms-and-conditions. All modifications will be
                    effective when they are posted. By continuing to access or use the Site or the Interface after those
                    revisions become effective, you agree to be bound by the revised Terms.
                  </p>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">6. RISKS</h3>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    6.1. The use of technology related to blockchain, smart contracts, and cryptocurrencies, among
                    others, entails a risk that by accessing transactions, you are assuming. pro.metawin.com does not
                    own or control any underlying software through which blockchain networks are formed. The software
                    underlying blockchain networks are open-source so anyone can use, copy, modify, and distribute it.
                    By using the Interface, you acknowledge and agree:
                  </p>
                  <ul className="list-style-none">
                    <li>
                      <p className="body-text">
                        6.1.1. That pro.metawin.com is not responsible for the operation of the open-source software and
                        networks underlying the Interface;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        6.1.2. That there exists no guarantee of the functionality, security, or availability of that
                        software and networks; and
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        6.1.3. That the underlying networks are subject to sudden changes in operating rules, such as
                        those commonly referred to as "forks," which may materially affect the Interface. You are
                        responsible for securing your private key(s). We do not have access to your private key(s);
                        losing control of your private key(s) will permanently and irreversibly deny you access to any
                        blockchain-based network. Neither pro.metawin.com nor any other person or entity will be able to
                        retrieve or protect your digital assets. If your private key(s) are lost, you will not be able
                        to transfer your digital assets to any blockchain address or wallet. If this occurs, you will
                        not be able to realize any value or utility from the digital assets you may hold.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="body-text">
                    6.2. pro.metawin.com is not responsible for the content of any third party, including, but not
                    limited to, information, materials, products, or services that pro.metawin.com does not own or
                    control. In addition, third parties may offer promotions related to your access and use of the
                    Interface. pro.metawin.com does not endorse or assume any responsibility for such resources or
                    promotions. Suppose you access any such resources or participate in any such promotions. In that
                    case, you do so at your own risk and understand that these Terms do not apply to your dealings or
                    relationships with any third parties. You expressly relieve pro.metawin.com of all liability arising
                    from using such resources or participating in such promotions.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    6.3. You understand that the Arbitrum blockchain remains under development, which creates
                    technological and security risks when using the Interface, in addition to uncertainty relating to
                    digital assets and transactions therein. You acknowledge that the cost of transacting on the
                    Arbitrum blockchain is variable and may increase at any time, causing an impact on any activities
                    taking place on these blockchains, which may result in price fluctuations or increased costs when
                    using the Interface.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    6.4. Transactions entered into in connection with the Interface are irreversible and final, and
                    there are no refunds. You acknowledge and agree that you will access and use the Interface at your
                    own risk.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    6.5. We must comply with Applicable Law, which may require us to, upon request by government
                    agencies, take certain actions or provide information that may not be in your best interests.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    6.6. You hereby assume and agree that pro.metawin.com will have no responsibility or liability for
                    the risks in Section 9. You hereby irrevocably waive, release and discharge all claims, whether
                    known or unknown to you, against pro.metawin.com, its affiliates, and their respective shareholders,
                    members, directors, officers, employees, agents, representatives, suppliers, and contractors related
                    to any of the risks set forth in this Section 6.
                  </p>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">7. PROHIBITED USES</h3>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    7.1 You agree not to engage in the prohibited uses set forth below. The specific activities set
                    forth below are representative but not exhaustive. By using the Site or the Interface, you confirm
                    that you will not do any of the following:
                  </p>
                  <ul className="list-style-none">
                    <li>
                      <p className="body-text">
                        7.1.1. Promote or facilitate illegal activities, including but not limited to money laundering,
                        terrorist financing, tax evasion, buying or selling illegal drugs, contraband, counterfeit
                        goods, or illegal weapons;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.2. Engage in transactions involving items that infringe or violate any copyright, trademark,
                        right of publicity, privacy, or any other proprietary right of pro.metawin.com;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.3. Engage in improper or abusive trading practices, including but not limited to (a) any
                        fraudulent act or scheme to defraud, deceive, trick, or mislead; (b) trading ahead of another
                        user of the Site or the Interface or front-running; (c) fraudulent trading; (d) accommodation
                        trading; (e) fictitious transactions; (f) pre-arranged or non-competitive transactions; or (g)
                        cornering;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.4. Uploading or transmitting viruses, worms, Trojan horses, time bombs, cancelbots, spiders,
                        malware, or any other type of malicious code that will or may be used in any way that will
                        affect the functionality or operation of the Site or the Interface;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.5. Use the Site or Interface in any way that is, in our sole discretion, libelous,
                        defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar,
                        suggestive, harassing, stalking, hateful, threatening, offensive, discriminatory, bigoted,
                        abusive, inflammatory, fraudulent, deceptive, or otherwise objectionable or likely or intended
                        to incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts
                        against others;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.6. Harass, abuse, or harm another person or entity, including pro.metawin.com's collaborator
                        and service providers;
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.7. Impersonate another user of the Site or the Interface or otherwise misrepresent yourself;
                        or
                      </p>
                    </li>
                    <li>
                      <p className="body-text">
                        7.1.8. Engage or attempt to engage or encourage, induce or assist any third party, or yourself
                        attempt, to engage in any of the activities prohibited under this Section 4 or any other
                        provision of these Terms.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">8. DISCLOSURES; DISCLAIMERS</h3>
              <p className="body-text">
                pro.metawin.com is an informational site for the Bsx Pro Platform. pro.metawin.com does not operate an
                exchange platform or offer trade execution or clearing services and has no oversight, involvement, or
                control concerning your transactions using the Interface. All transactions between users of the
                Interface are executed peer-to-peer directly between the users' blockchain addresses through a
                third-party developed open-source smart contract.
              </p>
              <p className="body-text">
                You are responsible for complying with all Applicable Laws that govern your Perpetual Contracts. As a
                result of restrictions under the Commodity Exchange Act and the regulations promulgated thereunder by
                the U.S. Commodity Futures Trading Commission ("CFTC"), no U.S. Person may enter into Perpetual
                Contracts using the Interface.
              </p>
              <p className="body-text">
                You understand that pro.metawin.com is not registered or licensed by any regulatory agency or authority.
                No such agency or authority has reviewed or approved the use of the Site or the Interface.
              </p>
              <p className="body-text">
                You agree that the Site and the Interface are provided on an "AS IS" and "AS AVAILABLE" basis.
                pro.metawin.com makes no guarantees of any kind or connection with the Site or the Interface.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">9. LIMITATION OF LIABILITY</h3>
              <p className="body-text">
                In no event shall pro.metawin.com, its affiliates, its suppliers and contractors, and its affiliates',
                suppliers' and contractors' respective stockholders, members, directors, officers, managers, employees,
                attorneys, agents, representatives, suppliers, and contractors shall be liable for any direct, indirect,
                incidental, special, punitive, consequential or similar damages or liabilities whatsoever (including,
                without limitation, damages for loss of fiat, assets, data, information, revenue, opportunities, use,
                goodwill, profits or other business or financial benefit) arising out of or in connection with the Site
                or the Interface, or other item provided by or on behalf of pro.metawin.com, whether under contract,
                tort (including negligence), civil liability, statute, strict liability, breach of warranties, or under
                any other theory of liability, and whether or not we have been advised of, knew of or should have known
                of the possibility of such damages and notwithstanding any failure of the essential purpose of these
                Terms or any limited remedy hereunder nor is pro.metawin.com in any way responsible for the execution or
                settlement of transactions between users of the Interface.
              </p>
              <p className="body-text">
                In no event shall pro.metawin.com's aggregate liability arising out of or in connection with the Site or
                Interface exceed one thousand Singapore Dollars (1,000.00 SGD).
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">10. INDEMNIFICATION</h3>
              <p className="body-text">
                You will defend, indemnify, and hold harmless pro.metawin.com, its affiliates, members, member,
                managers, employees, attorneys, representatives, suppliers, and contractors from any claim, demand,
                lawsuit, action, proceeding, investigation, liability, damage, loss, cost or expense, including without
                limitation reasonable attorneys' fees, arising out of or relating to (a) your use of or conduct in
                connection with the Site or the Interface (b) your violation of these Terms; or (c) your misuse of the
                Site or the Interface, or any smart contract and/or script related thereto; (d) your violation of any
                laws, rules, regulations, codes, statutes, ordinances, or orders of any governmental or
                quasi-governmental authorities; (e) your violation of the rights of any third party, including any
                intellectual property right, publicity, confidentiality, property, or privacy right; (f) your use of a
                third-party product, service, and/or website; or (g) any misrepresentation made by you. We reserve the
                right to assume, at your expense, the exclusive defense, and control of any matter subject to
                indemnification by you. You agree to cooperate with our defense of any claim. You will not, in any
                event, settle any claim without.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">11. DISPUTE RESOLUTION & ARBITRATION</h3>
              <p className="body-text">
                pro.metawin.com will use its best efforts to resolve potential disputes through informal, good faith
                negotiations. If a potential dispute arises, you must contact us by sending a written notice of your
                claim ("Notice") to pro.metawin.com on any of our official channels. The notice must (a) describe the
                nature and basis of the claim and (b) set forth the specific relief sought. Our notice to you will be
                similar in form to that described above. If you and pro.metawin.com cannot reach an agreement to resolve
                the claim within sixty (60) days of your email, then you and pro.metawin.com agree to resolve the
                potential dispute according to the process set forth below.
              </p>
              <p className="body-text">
                Any claim or controversy arising out of or relating to the Site, the Interface, or these Terms, or any
                other acts or omissions for which you may contend that we are liable, including (but not limited to) any
                claim or controversy as to arbitrability ("Dispute"), shall be finally and exclusively settled by
                arbitration under the Corte Civil y Mercantil de Arbitraje (CIMA). You understand that you are required
                to resolve all Disputes by binding arbitration. The arbitration shall be confidential before a single
                arbitrator, who shall be selected pursuant to the CIMA rules. The arbitration will be held in Madrid,
                Spain, unless you and we both agree to hold it elsewhere. Unless we agree otherwise, the arbitrator may
                not consolidate your claims with those of any other party. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent jurisdiction.
              </p>
              <p className="body-text">
                Any claim arising out of or related to these Terms or the Site or the Interface must be filed within one
                year after such claim arose; otherwise, the claim is permanently barred, which means that you and
                pro.metawin.com will not have the right to assert the claim.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">12. GOVERNING LAW</h3>
              <p className="body-text">
                The interpretation and enforcement of these Terms, and any dispute related to these Terms, the Site, or
                the Interface, will be governed by and construed and enforced under the laws of the Bahamas, as
                applicable.
              </p>
            </div>
            <div className="section">
              <h3 className="body-title">13. GENERAL INFORMATION</h3>
              <ul className="list-style-none">
                <li>
                  <p className="body-text">
                    13.1. Any right or remedy of pro.metawin.com set forth in these Terms is in addition to, and not in
                    lieu of, any other right or remedy whether described in these Terms, under Applicable Law, at law,
                    or in equity. The failure or delay of pro.metawin.com in exercising any right, power, or privilege
                    under these Terms shall not operate as a waiver thereof.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.2. The following sections of these Terms will survive any termination of your access to the Site
                    or the Interface, regardless of the reasons for its expiration or termination, in addition to any
                    other provision which by law or by its nature should survive: Sections 3 through 12.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.3. The invalidity or unenforceability of any of these Terms shall not affect the validity or
                    enforceability of any other of these Terms, all of which shall remain in full force and effect.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.4. pro.metawin.com will have no responsibility or liability for any failure or delay in
                    performance of the Site or the Interface, or any loss or damage that you may incur, due to any
                    circumstance or event beyond our control, including without limitation any flood, extraordinary
                    weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute,
                    accident, any law, order regulation, direction, action or request of the government, communications,
                    power failure, or equipment or software malfunction.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.5. You may not assign or transfer any right to use the Site, the Interface, or any of your rights
                    or obligations under these Terms, without our express prior written consent, including by operation
                    of law or in connection with any change of control. We may assign or transfer any or all of our
                    rights or obligations under these Terms, in whole or part, without notice or obtaining your consent
                    or approval.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.6. These Terms contain the entire agreement between you and pro.metawin.com and supersede all
                    prior and contemporaneous understandings between the parties regarding the Interface and the Site or
                    the Interface.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.7. In the event of any conflict between these Terms and any other agreement you may have with us,
                    these Terms will control unless the other agreement specifically identifies these Terms and declares
                    that the other agreement supersedes these Terms.
                  </p>
                </li>
                <li>
                  <p className="body-text">
                    13.8. You agree that, except as otherwise expressly provided in these Terms, there shall be no
                    third-party beneficiaries to the Terms other than the Indemnified Parties.
                  </p>
                </li>
              </ul>
            </div>
            <div className="section">
              <h3 className="body-title">CONTACT INFORMATION</h3>
              <p className="body-text">
                If you have any questions about these Terms, the Site, or the Interface, please get in touch with
                pro.metawin.com on any of our official channels.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
