import { ARBITRUM, ARBITRUM_TESTNET } from "./chains";

export const BSX_STATS_API_URL = "https://localhost:3113";

const BACKEND_URLS = {
  default: "https://gmx-server-mainnet.uw.r.appspot.com",

  [ARBITRUM_TESTNET]: "https://localhost:3113",
  [ARBITRUM]: "https://gambit-server-devnet.uc.r.appspot.com",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function getSupplyUrl(route = "/totalSupply") {
  // same supply across networks
  // return "https://stats.metawin.com/total_supply";
  return `${BSX_STATS_API_URL}/bsx${route}`;
}
