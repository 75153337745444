import { t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import { getIcon } from "config/icons";
import { callContract } from "lib/contracts";
import { useState } from "react";
import Button from "components/Button/Button";
import Faucet from "abis/Faucet.json";

export default function FaucetCard(props) {
  const { chainId } = useChainId();

  const blpIcon = getIcon("common", "blp");
  const bsxIcon = getIcon("common", "bsx");

  const fbtcAddress = getContract(chainId, "FBTC");
  const fethAddress = getContract(chainId, "FETH");
  const fusdtAddress = getContract(chainId, "FUSDT");
  const fusdcAddress = getContract(chainId, "FUSDC");

  const { active, library } = useWeb3React();
  const { setPendingTxns, connectWallet } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickPrimary = (token) => {
    if (!active) {
      connectWallet();
      return;
    }

    claimToken(token);
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }

    if (isSubmitting) {
      return t`Claiming...`;
    }

    return t` Claim Tokens`;
  };

  const claimToken = (token) => {
    setIsSubmitting(true);
    if (token === "FBTC") {
      const fbtcContract = new ethers.Contract(fbtcAddress, Faucet.abi, library.getSigner());

      callContract(chainId, fbtcContract, "claimDroplet", [], {
        value: 0,
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`FBTC Claimed Successfully!`,
        setPendingTxns,
      })
        .then(async () => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (token === "FETH") {
      const fethContract = new ethers.Contract(fethAddress, Faucet.abi, library.getSigner());
      callContract(chainId, fethContract, "claimDroplet", [], {
        value: 0,
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`FETH Claimed Successfully!`,
        setPendingTxns,
      })
        .then(async () => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (token === "FUSDT") {
      const fusdtContract = new ethers.Contract(fusdtAddress, Faucet.abi, library.getSigner());

      callContract(chainId, fusdtContract, "claimDroplet", [], {
        value: 0,
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`FUSDT Claimed Successfully!`,
        setPendingTxns,
      })
        .then(async () => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (token === "FUSDC") {
      const fusdcContract = new ethers.Contract(fusdcAddress, Faucet.abi, library.getSigner());

      callContract(chainId, fusdcContract, "claimDroplet", [], {
        value: 0,
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`FUSDC Claimed Successfully!`,
        setPendingTxns,
      })
        .then(async () => {})
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className="Home-token-card-options">
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={bsxIcon} width="40" alt="BSX Icons" /> BTC
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-action">
            <div className="claim-cta claim-token-button-container">
              <Button type="submit" variant="primary-action" className="w-100" onClick={() => onClickPrimary("FBTC")}>
                {getPrimaryText()}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={blpIcon} width="40" alt="BLP Icon" /> WETH
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-action">
            <div className="claim">
              <Button type="submit" variant="primary-action" className="w-100" onClick={() => onClickPrimary("FETH")}>
                {getPrimaryText()}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={blpIcon} width="40" alt="BLP Icon" /> USDT
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-action">
            <div className="claim">
              <Button type="submit" variant="primary-action" className="w-100" onClick={() => onClickPrimary("FUSDT")}>
                {getPrimaryText()}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={blpIcon} width="40" alt="BLP Icon" /> USDC
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-action">
            <div className="claim">
              <Button type="submit" variant="primary-action" className="w-100" onClick={() => onClickPrimary("FUSDC")}>
                {getPrimaryText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
