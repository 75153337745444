import { Provider } from "@ethersproject/providers";
import { Contract } from "ethers";
import { GAS_PRICE_ADJUSTMENT_MAP} from "config/chains";
import { bigNumberify } from "../numbers";

export async function setGasPrice(txnOpts: any, provider: Provider, chainId: number) {
  const premium = GAS_PRICE_ADJUSTMENT_MAP[chainId] || bigNumberify(0);

  const gasPrice = await provider.getGasPrice();

  txnOpts.gasPrice = gasPrice.add(premium);
  return;
}

export async function getGasLimit(contract: Contract, method, params = [], value) {
  const defaultValue = bigNumberify(0);

  if (!value) {
    value = defaultValue;
  }

  let gasLimit = await contract.estimateGas[method](...params, { value });

  if (gasLimit.lt(22000)) {
    gasLimit = bigNumberify(22000)!;
  }

  return gasLimit.mul(11000).div(10000); // add a 10% buffer
}
