import { ARBITRUM, ARBITRUM_TESTNET, ETH_MAINNET, BASE, BASE_GOERLI } from "./chains";
import { isDevelopment } from "./env";
import { getSubgraphUrlKey } from "./localStorage";

const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    // stats: "https://api.thegraph.com/subgraphs/name/gdev8317/bsx-arbitrum-stats
    stats: "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-stats/api",
    referrals: "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-referrals/api",
    vault:
      "https://subgraph.satsuma-prod.com/metawin/metawin-base-goerli-vault/version/master-230929221047-1b48474/api",
  },
  [ARBITRUM_TESTNET]: {
    // stats: "https://api.thegraph.com/subgraphs/name/gdev8317/bsx-arbitrum-stats
    stats: "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-stats/api",
    referrals: "https://subgraph.satsuma-prod.com/18fd13baf584/metawin/metawin-arbitrum-goerli-referrals/api",
    vault:
      "https://subgraph.satsuma-prod.com/metawin/metawin-base-goerli-vault/version/master-230929221047-1b48474/api",
  },
  [BASE]: {
    stats: "",
    referrals: "",
    vault: "",
  },
  [BASE_GOERLI]: {
    stats: "",
    referrals: "",
    vault: "",
  },

  [ETH_MAINNET]: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};

export function getSubgraphUrl(chainId: number, subgraph: string) {
  if (isDevelopment()) {
    const localStorageKey = getSubgraphUrlKey(chainId, subgraph);
    const url = localStorage.getItem(localStorageKey);
    if (url) {
      // eslint-disable-next-line no-console
      console.warn("%s subgraph on chain %s url is overriden: %s", subgraph, chainId, url);
      return url;
    }
  }

  return SUBGRAPH_URLS[chainId][subgraph];
}
