/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from "ethers";
import { gql } from "@apollo/client";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Token as UniToken } from "@uniswap/sdk-core";
import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";

import OrderBook from "../abis/OrderBook.json";
import PositionManager from "../abis/PositionManager.json";
import Vault from "../abis/Vault.json";
import Router from "../abis/Router.json";
import StakedBsx from "../abis/RewardTracker.json";
import UniPool from "../abis/UniPool.json";
import Token from "../abis/Token.json";
import RewardsTracker from "../abis/RewardTracker.json";

import { getContract } from "../config/contracts";
import { getConstant } from "../config/chains";

import {
  bigNumberify,
  getExplorerUrl,
  setGasPrice,
  getGasLimit,
  replaceNativeTokenAddress,
  getProvider,
  getOrderKey,
  fetcher,
  parseValue,
  expandDecimals,
  helperToast,
  USD_DECIMALS,
  ETH_DECIMALS,
  ARBITRUM,
  ARBITRUM_TESTNET,
  SECONDS_PER_YEAR,
  BASE,
} from "../Helpers";
import { getTokenBySymbol } from "../config/tokens";

import { arbitrumGoerliVaultGraphClient, arbitrumGraphClient, arbitrumTestnetGraphClient } from "./common";
import { getServerUrl, getSupplyUrl } from "../config/backend";
import { getBsxStatsGraphClient } from "lib/subgraph";
export * from "./prices";

const { AddressZero } = ethers.constants;

function getBsxGraphClient(chainId) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === ARBITRUM_TESTNET) {
    return arbitrumTestnetGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

export function useFees(chainId) {
  const query = gql(`{
    feeStat(id: "total") {
      swap
      marginAndLiquidation
      mint
      burn
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setRes).catch();
  }, [setRes, query, chainId]);

  return res ? res.data.feeStat : null;
}

export function useFeesSince(chainId, from, to) {
  const [res, setRes] = useState();

  const query = gql(`{
    feeStats(where: { id_gte: ${from}, id_lt: ${to}, period: daily }) {
      id
      marginAndLiquidation
      swap
      mint
      burn
    },
  }`);

  useEffect(() => {
    if (!from) {
      return;
    }
    getBsxGraphClient(chainId)
      .query({ query })
      .then((res) => {
        if (res.data.feeStats) {
          let fees = res.data.feeStats.reduce(
            (sum, stat) => sum.add(stat.mint).add(stat.burn).add(stat.swap).add(stat.marginAndLiquidation),
            bigNumberify(0)
          );
          setRes(fees);
        }
      })
      .catch();
  }, [setRes, query, chainId, from]);

  return res;
}

const FEE_MULTIPLIER_BASIS_POINTS = 4;
const MM_FEE_MULTIPLIER = bigNumberify(6);
const MM_SWAPS_FEE_MULTIPLIER = bigNumberify(12);

export function useSpreadCaptureVolume(chainId) {
  // spread capture turned off
  const to = 1665792441;
  const query = gql(`{
    volumeStats(first: 1000, period: daily, orderBy: id, orderDirection: desc, where: { id_lt: ${to} }) {
      margin
      liquidation
      swap
      mint
      burn
    }
  }`);

  const [res, setRes] = useState(undefined);

  useEffect(() => {
    getBsxStatsGraphClient(chainId)
      .query({ query })
      .then((res) => {
        const totalMMFees = res.data.volumeStats.reduce(
          (sum, stat) =>
            sum
              .add(MM_FEE_MULTIPLIER.mul(stat.mint))
              .add(MM_FEE_MULTIPLIER.mul(stat.burn))
              .add(MM_FEE_MULTIPLIER.mul(stat.margin))
              .add(MM_FEE_MULTIPLIER.mul(stat.liquidation))
              .add(MM_SWAPS_FEE_MULTIPLIER.mul(stat.swap)),
          bigNumberify(0)
        );
        setRes(totalMMFees.div(expandDecimals(1, FEE_MULTIPLIER_BASIS_POINTS)));
      })
      .catch();
  }, [setRes, query, chainId]);

  return res;
}

export function useVolume(chainId) {
  const query = gql(`{
    volumeStat(id: "total") {
      margin
      liquidation
      swap
      mint
      burn
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setRes).catch();
  }, [setRes, query, chainId]);

  return res ? res.data.volumeStat : null;
}

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setRes).catch();
  }, [setRes, query, chainId]);

  return res ? res.data.orderStat : null;
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setRes).catch();
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getBsxGraphClient(chainId);
      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        .catch();
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, library) {
  const count = 1000;
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    arbitrumGoerliVaultGraphClient.query({ query }).then(setRes).catch();
  }, [setRes, query]);

  const key = res ? `allPositions${count}__` : false;
  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
          const position = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          };
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate);
          position.marginFee = position.size.div(1000);
          position.fee = position.fundingFee.add(position.marginFee);

          const THRESHOLD = 5000;
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
          position.danger = collateralDiffPercent.gt(THRESHOLD);

          return position;
        } catch (ex) {}
      })
    );

    return ret.filter(Boolean);
  });

  return positions;
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setRes);
  }, [setRes, query, chainId]);

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {}
      })
    );
  });

  return orders.filter(Boolean);
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {}
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTrades(chainId, account) {
  let url = getServerUrl(chainId, "/actions");
  if (account && account.length) {
    url += `&account=${account}`;
  }

  const { data, mutate: updateTrades } = useSWR(url, {
    dedupingInterval: 30000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  // Convert the response to match expected format
  let trades = [];
  if (Array.isArray(data)) {
    trades = data.map((datum) => {
      if (datum.dataValues) {
        return {
          id: datum.dataValues.id.toString(),
          data: {
            ...datum.dataValues,
            params: JSON.stringify(datum.dataValues.params),
          },
        };
      } else {
        return {
          id: datum.id,
          data: {
            ...datum,
            params: JSON.stringify(datum.params),
          },
        };
      }
    });
  }

  if (trades) {
    trades.sort((item0, item1) => {
      const data0 = item0.data;
      const data1 = item1.data;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useHasOutdatedUi() {
  return { data: false };
}

export function useBSXPrice(chainId, libraries, active) {
  const arbitrumLibrary = libraries && libraries.arbitrum ? libraries.arbitrum : undefined;
  const { data: bsxPriceFromArbitrum, mutate: mutateFromArbitrum } = useBSXPriceFromArbitrum(arbitrumLibrary, active);

  const bsxPrice = chainId === ARBITRUM ? bsxPriceFromArbitrum : bsxPriceFromArbitrum; //: bsxPriceFromBase;

  const mutate = useCallback(() => {
    mutateFromArbitrum();
  }, [mutateFromArbitrum]);

  return {
    bsxPrice,
    bsxPriceFromArbitrum,
    mutate,
  };
}

export function useTotalBSXSupply() {
  const { data: bsxSupply, mutate: updateBSXSupply } = useSWR([getSupplyUrl("/totalSupply")], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  const { data: circulatingBsxSupply, mutate: updateBSXCirculatingSupply } = useSWR(
    [getSupplyUrl("/circulatingSupply")],
    {
      fetcher: (...args) => fetch(...args).then((res) => res.text()),
    }
  );

  const mutate = useCallback(() => {
    updateBSXSupply();
    updateBSXCirculatingSupply();
  }, [updateBSXSupply, updateBSXCirculatingSupply]);

  return {
    total: bsxSupply ? bigNumberify(ethers.utils.parseUnits(bsxSupply, 18)) : undefined,
    circulating: circulatingBsxSupply ? bigNumberify(ethers.utils.parseUnits(circulatingBsxSupply, 18)) : undefined,
    mutate,
  };
}

export function useTotalBSXInLiquidity() {
  let poolAddressArbitrum = {
    uniswap: getContract(ARBITRUM, "UniswapBsxEthPool"),
    uniswapBsxEthPool: getContract(ARBITRUM, "UniswapBsxEthPool"),
    balancer: getContract(ARBITRUM, "BalancerVault"),
  };
  let totalBSXArbitrum = useRef(bigNumberify(0));
  let totalBSXBase = useRef(bigNumberify(0));

  const { data: bsxInUniswapLiquidityOnArbitrum, mutate: mutateBSXInUniswapLiquidityOnArbitrum } = useSWR(
    [
      `StakeV2:bsxInLiquidity:${ARBITRUM}`,
      ARBITRUM,
      getContract(ARBITRUM, "BSX"),
      "balanceOf",
      poolAddressArbitrum.uniswap,
    ],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const { data: bsxInUniswapLiquidityOnBase, mutate: mutateBSXInUniswapLiquidityOnBase } = useSWR(
    [`StakeV2:bsxInLiquidity:${BASE}`, BASE, getContract(BASE, "BSX"), "balanceOf", poolAddressArbitrum.uniswap],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    mutateBSXInUniswapLiquidityOnArbitrum();
    mutateBSXInUniswapLiquidityOnBase();
  }, [mutateBSXInUniswapLiquidityOnArbitrum, mutateBSXInUniswapLiquidityOnBase]);

  if (bsxInUniswapLiquidityOnArbitrum) {
    let total = bigNumberify(bsxInUniswapLiquidityOnArbitrum);
    totalBSXArbitrum.current = total;
  }

  if (bsxInUniswapLiquidityOnBase) {
    let total = bigNumberify(bsxInUniswapLiquidityOnBase);
    totalBSXBase.current = total;
  }

  return {
    base: totalBSXBase.current,
    arbitrum: totalBSXArbitrum.current,
    total: totalBSXArbitrum.current.add(totalBSXBase.current),
    mutate,
  };
}

function useBSXPriceFromArbitrum(library, active) {
  const poolAddress = getContract(ARBITRUM, "UniswapBsxEthPool");
  // const poolAddress = getContract(ARBITRUM, "UniswapBsxEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR(
    [`StakeV2:uniPoolSlot0:${active}`, ARBITRUM, poolAddress, "slot0"],
    {
      fetcher: fetcher(library, UniPool),
    }
  );

  const vaultAddress = getContract(ARBITRUM, "Vault");
  const ethAddress = getTokenBySymbol(ARBITRUM, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice:${active}`, ARBITRUM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const bsxPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(ARBITRUM, ethAddress, 18, "SYMBOL", "NAME");

      const bsxAddress = getContract(ARBITRUM, "BSX");
      const tokenB = new UniToken(ARBITRUM, bsxAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: bsxPrice, mutate };
}

export async function approvePlugin(
  chainId,
  pluginAddress,
  { library, pendingTxns, setPendingTxns, sentMsg, failMsg }
) {
  const routerAddress = getContract(chainId, "Router");
  const contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner());
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  });
}

export async function createSwapOrder(
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelMultipleOrders(chainId, library, swapIndexes, increaseIndexes, decreaseIndexes, opts) {
  const params = [swapIndexes, increaseIndexes, decreaseIndexes];
  const method = "cancelMultiple";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  orderBookAddress,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  orderBookAddress,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(
  chainId,
  orderBookAddress,
  library,
  index,
  minOut,
  triggerRatio,
  triggerAboveThreshold,
  opts
) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

const NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS";
const USER_DENIED = "USER_DENIED";
const SLIPPAGE = "SLIPPAGE";
const TX_ERROR_PATTERNS = {
  [NOT_ENOUGH_FUNDS]: ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"],
  [USER_DENIED]: ["User denied transaction signature"],
  [SLIPPAGE]: ["Router: mark price lower than limit", "Router: mark price higher than limit"],
};
export function extractError(ex) {
  if (!ex) {
    return [];
  }
  const message = ex.data?.message || ex.message;
  if (!message) {
    return [];
  }
  for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
    for (const pattern of patterns) {
      if (message.includes(pattern)) {
        return [message, type];
      }
    }
  }
  return [message];
}

function ToastifyDebug(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="Toastify-debug">
      {!open && (
        <span className="Toastify-debug-button" onClick={() => setOpen(true)}>
          Show error
        </span>
      )}
      {open && props.children}
    </div>
  );
}

export function useStakingApr(bsxPrice, ethPrice) {
  const [stakingApr, setStakingApr] = useState(null);

  // apr is annualised rewards (USD value) / total staked (USD value) * 100
  const { data: tokensPerInterval } = useSWR(
    [
      `useStakingApr:tokensPerInterval:${ARBITRUM}`,
      ARBITRUM,
      getContract(ARBITRUM, "BSXStakingRewards"),
      "tokensPerInterval",
    ],
    {
      fetcher: fetcher(undefined, RewardsTracker),
    }
  );

  const bsxTokenAddress = getContract(ARBITRUM, "BSX");
  const { data: bsxDeposited } = useSWR(
    [
      `useStakingApr:totalDepositSupply(BSX):${ARBITRUM}`,
      ARBITRUM,
      getContract(ARBITRUM, "BSXStakingRewards"),
      "totalDepositSupply",
    ],
    {
      fetcher: fetcher(undefined, RewardsTracker, bsxTokenAddress),
    }
  );

  const esBsxTokenAddress = getContract(ARBITRUM, "ES_BSX");
  const { data: esBsxDeposited } = useSWR(
    [
      `useStakingApr:totalDepositSupply(esBSX):${ARBITRUM}`,
      ARBITRUM,
      getContract(ARBITRUM, "BSXStakingRewards"),
      "totalDepositSupply",
    ],
    {
      fetcher: fetcher(undefined, RewardsTracker, esBsxTokenAddress),
    }
  );

  useEffect(() => {
    if (ethPrice?.gt(0) && bsxPrice?.gt(0) && tokensPerInterval && bsxDeposited && esBsxDeposited) {
      const tokensPerYear = tokensPerInterval.mul(SECONDS_PER_YEAR);
      const annualRewardsUsd = tokensPerYear.mul(ethPrice);

      const totalDepositTokens = bsxDeposited.add(esBsxDeposited);
      const totalDepositsUsd = totalDepositTokens.mul(bsxPrice);

      const aprPrecision = 10;
      const apr = annualRewardsUsd.mul(expandDecimals(1, aprPrecision)).div(totalDepositsUsd);

      const formattedApr = (apr.toNumber() / 10 ** aprPrecision) * 100;
      setStakingApr(formattedApr.toFixed(2));
    }
  }, [ethPrice, bsxPrice, tokensPerInterval, bsxDeposited, esBsxDeposited]);

  return stakingApr;
}

export function useTotalStaked() {
  const [totalStakedBsx, setTotalStakedBsx] = useState(null);

  const { data: bsxAssetsInStaking } = useSWR(
    [`DashboardV2:bsxInStaking:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "StakedBsx"), "totalAssets"],
    {
      fetcher: fetcher(undefined, StakedBsx),
    }
  );

  const { data: pendingBsxDepositsInStaking } = useSWR(
    [`DashboardV2:pendingBsxInStaking:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "StakedBsx"), "pendingDeposits"],
    {
      fetcher: fetcher(undefined, StakedBsx),
    }
  );

  useEffect(() => {
    if (bsxAssetsInStaking && pendingBsxDepositsInStaking && !totalStakedBsx) {
      const bsxDeposited = bsxAssetsInStaking.add(pendingBsxDepositsInStaking).div(expandDecimals(1, ETH_DECIMALS));

      if (!totalStakedBsx) {
        setTotalStakedBsx(bsxDeposited);
      }
    }
  }, [bsxAssetsInStaking, pendingBsxDepositsInStaking, totalStakedBsx]);

  return totalStakedBsx;
}

export async function callContract(chainId, contract, method, params, opts) {
  try {
    if (!Array.isArray(params) && typeof params === "object" && opts === undefined) {
      opts = params;
      params = [];
    }
    if (!opts) {
      opts = {};
    }

    const txnOpts = {};

    if (opts.value) {
      txnOpts.value = opts.value;
    }

    txnOpts.gasLimit = opts.gasLimit ? opts.gasLimit : await getGasLimit(contract, method, params, opts.value);

    await setGasPrice(txnOpts, contract.provider, chainId);

    const res = await contract[method](...params, txnOpts);
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
    const sentMsg = opts.sentMsg || "Transaction sent.";
    helperToast.success(
      <div>
        {sentMsg}{" "}
        <a href={txUrl} target="_blank" rel="noopener noreferrer">
          View status.
        </a>
        <br />
      </div>
    );
    if (opts.setPendingTxns) {
      const pendingTxn = {
        hash: res.hash,
        message: opts.successMsg || "Transaction completed!",
      };

      opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
    }
    return res;
  } catch (e) {
    let failMsg;
    const [message, type] = extractError(e);
    switch (type) {
      case NOT_ENOUGH_FUNDS:
        failMsg = (
          <div>
            There is not enough ETH in your account on Arbitrum to send this transaction.
            <br />
            <br />
            <a href={"https://arbitrum.io/bridge-tutorial/"} target="_blank" rel="noopener noreferrer">
              Bridge ETH to Arbitrum
            </a>
          </div>
        );
        break;
      case USER_DENIED:
        failMsg = "Transaction was cancelled.";
        break;
      case SLIPPAGE:
        failMsg =
          'The mark price has changed, consider increasing your Allowed Slippage by clicking on the "..." icon next to your address.';
        break;
      default:
        failMsg = (
          <div>
            {opts.failMsg || "Transaction failed."}
            <br />
            {message && <ToastifyDebug>{message}</ToastifyDebug>}
          </div>
        );
    }
    helperToast.error(failMsg);
    throw e;
  }
}

export function useBlpPrices(chainId, currentBlpPrice) {
  const query = gql(`{
    blpStats(
      first: 1000,
      orderBy: id,
      orderDirection: asc,
      where: { period: daily }
    ) {
      id
      aumInUsdg
      blpSupply
      distributedUsd
      distributedEth
    },
    feeStats (
      first: 1000,
      orderBy: id,
      orderDirection: asc,
      where: { period: daily }
    ) {
      id
      margin
      marginAndLiquidation
      swap
      liquidation
      mint
      burn
    }
  }`);

  const [data, setData] = useState();

  useEffect(() => {
    getBsxGraphClient(chainId).query({ query }).then(setData).catch();
  }, [setData, query, chainId]);

  let cumulativeDistributedUsdPerBlp = 0;
  let cumulativeDistributedEthPerBlp = 0;
  const blpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    let prevBlpSupply;
    let prevAum;

    const feeStatsById = data.data.feeStats.reduce(
      (o, stat) => ({
        ...o,
        [stat.id]: ethers.BigNumber.from(stat.marginAndLiquidation).add(stat.swap).add(stat.mint).add(stat.burn),
      }),
      {}
    );

    let cumulativeFees = ethers.BigNumber.from(0);
    let ret = data.data.blpStats
      .filter((item) => item.id % 86400 === 0)
      .reduce((memo, item, i) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdg) / 1e18;
        const blpSupply = Number(item.blpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerBlp = distributedUsd / blpSupply || 0;
        cumulativeDistributedUsdPerBlp += distributedUsdPerBlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerBlp = distributedEth / blpSupply || 0;
        cumulativeDistributedEthPerBlp += distributedEthPerBlp;

        const feeStat = feeStatsById[item.id] ?? ethers.BigNumber.from(0);
        cumulativeFees = cumulativeFees.add(feeStat);
        const totalFees = parseFloat(ethers.utils.formatUnits(cumulativeFees, USD_DECIMALS));

        const blpPrice = aum / blpSupply;
        const blpPriceWithFees = (totalFees + aum) / blpSupply;

        const timestamp = parseInt(item.id);

        const newItem = {
          time: timestamp,
          aum,
          blpSupply,
          value: blpPrice,
          blpPriceWithFees: blpPriceWithFees,
          cumulativeDistributedEthPerBlp,
          cumulativeDistributedUsdPerBlp,
          distributedUsdPerBlp,
          distributedEthPerBlp,
        };
        if (i === data.data.blpStats.length - 1 && currentBlpPrice) {
          newItem.blpPriceWithFees = Number.isNaN(blpPrice)
            ? parseFloat(ethers.utils.formatUnits(currentBlpPrice, USD_DECIMALS))
            : blpPriceWithFees;
          newItem.value = parseFloat(ethers.utils.formatUnits(currentBlpPrice, USD_DECIMALS));
        }

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }
        return memo;
      }, [])
      .map((item) => {
        let { blpSupply, aum } = item;
        if (!blpSupply) {
          blpSupply = prevBlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.blpSupplyChange = prevBlpSupply ? ((blpSupply - prevBlpSupply) / prevBlpSupply) * 100 : 0;
        if (item.blpSupplyChange > 1000) item.blpSupplyChange = 0;
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) item.aumChange = 0;
        prevBlpSupply = blpSupply;
        prevAum = aum;
        return item;
      });

    // ret = fillNa(ret);
    return ret;
  }, [data, currentBlpPrice]);

  return blpChartData;
}
