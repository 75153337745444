import { ARBITRUM, ARBITRUM_TESTNET, BASE, BASE_GOERLI } from "./chains";

const CONTRACTS = {
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0x32cAb06705Bf240155a0637D0FdF77E9084B17DD",
    Router: "0x124C59b3C32bBe3a57B82f382058D1b520b1cf57",
    VaultReader: "0x921A6C18EDd5537564373D5795409285d9a70e56",
    Reader: "0x7543BaB98465529BBBcd0ccAe61ac47BEb4fbE21",
    BlpManager: "0x6b2B3174FdB5E282AdE3dDD736328C9c303A8E41",
    RewardRouter: "0x77b4963A2fB2cf9b4Dd4aaA3B2BED839688738c9",
    BlpRewardRouter: "0x6d834011Ad3e4CB0A47Fc1452B2d3D2ebB321E01",
    RewardReader: "0x881e2aC78DF5879d1C381Fc7E27F4D5e4443Ffa5",
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BLP: "0x2fBd059CCbFf9bF8127D3409AB21E083E217fbd4",
    BSX: "0xF5920D3b7fc06eaCAEC27C9B1516C41FF16fB8a9",
    ES_BSX: "0x638a55e936AbE045eAc18209f0479aafB037A719",
    BN_BSX: "0x7F4C47d84F62897ea99e19Df1CBB603317d52b2D",
    USDM: "0xaD4abAF0C23aEc651c95865F14A0b9faEfc04F9E",

    StakedBsxTracker: "0x05635d66d9C110E51d00388f955b44877b3d3679",
    BonusBsxTracker: "0x0D6eB58c6798f5211421778649BeE330c1065d88",
    FeeBsxTracker: "0x18731B2911571B00573F0bc97C393F9D6C4dCb80",

    FeeBlpTracker: "0x57E5B2C04E106a907EbcDd42c69d742De1dC5B49",
    StakedBlpTracker: "0xD60b526164ca64Fe18008635B00d7906754a28b0",

    StakedBsxDistributor: "0x7cF71cB56237FcbA112DE57288580A4794DB4a51",
    StakedBlpDistributor: "0x833576194905bEbE5E83519BF455aD9687faBdDA",

    BsxVester: "0xAEb31B5c098fe430Bd866a26e57c2082e16AB5d4",
    BlpVester: "0xDC6E23722D9620Ab41122206076Be4362edc22B2",

    OrderBook: "0x5EF876316C6c4AF00e74A5E53f94Fc2A0e72574F",
    OrderBookReader: "0x9231436F75e144dD6074c62DF714e5599F85FCd1",

    PositionRouter: "0xa9a36fdA1619a8B9A1e4a16ABa71ef006d678f17",
    PositionManager: "0x4D365963ac0d7c6Be5cF466303Bb98d12d754Ed8",
    // PositionManager: "0x854b2Ee4E60f5135FE431864372f194432363F56",

    // UniswapBsxEthPool: "",
    ReferralStorage: "0xb5d6886515F5B55381aBb08e59cDfb491757d917",
    ReferralReader: "0x0Da39af06F7f7Be2b3e74f57611AC2D85A1339B0",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    UniswapBsxEthPool: "0x9fa7166e8e3D036BF5aFB4AEC252882b3CDF43A7",

    //Faucet Tokens
    FBTC: "0x41E26e1285D9F7950d6371cEe2CC45e4F178C9d7",
    FETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    FUSDT: "0xdA330C3Eb4E177691E1f6c105Fb01df27dA4AB27",
    FUSDC: "0x3076A30D0Ea899508737961e1926f925689f5c95",
  },

  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    BlpManager: "0x3963FfC9dff443c2A94f21b129D429891E32ec18",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    BlpRewardRouter: "0xB95DB5B167D75e6d04227CfFFA61069348d271F5",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    BLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    BSX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_BSX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_BSX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDM: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_BSX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedBsxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusBsxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeBsxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedBlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeBlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedBsxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedBlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    BsxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    BlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapBsxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",

    Timelock: "0xaa50bD556CE0Fe61D4A57718BA43177a3aB6A597",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },

  [BASE]: {
    // base mainnet
    Vault: "0x32cAb06705Bf240155a0637D0FdF77E9084B17DD",
    Router: "0x124C59b3C32bBe3a57B82f382058D1b520b1cf57",
    VaultReader: "0x921A6C18EDd5537564373D5795409285d9a70e56",
    Reader: "0x7543BaB98465529BBBcd0ccAe61ac47BEb4fbE21",
    BlpManager: "0x6b2B3174FdB5E282AdE3dDD736328C9c303A8E41",
    RewardRouter: "0x77b4963A2fB2cf9b4Dd4aaA3B2BED839688738c9",
    BlpRewardRouter: "0x6d834011Ad3e4CB0A47Fc1452B2d3D2ebB321E01",
    RewardReader: "0x881e2aC78DF5879d1C381Fc7E27F4D5e4443Ffa5",
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BLP: "0x2fBd059CCbFf9bF8127D3409AB21E083E217fbd4",
    BSX: "0xF5920D3b7fc06eaCAEC27C9B1516C41FF16fB8a9",
    ES_BSX: "0x638a55e936AbE045eAc18209f0479aafB037A719",
    BN_BSX: "0x7F4C47d84F62897ea99e19Df1CBB603317d52b2D",
    USDM: "0xaD4abAF0C23aEc651c95865F14A0b9faEfc04F9E",

    StakedBsxTracker: "0x05635d66d9C110E51d00388f955b44877b3d3679",
    BonusBsxTracker: "0x0D6eB58c6798f5211421778649BeE330c1065d88",
    FeeBsxTracker: "0x18731B2911571B00573F0bc97C393F9D6C4dCb80",

    FeeBlpTracker: "0x57E5B2C04E106a907EbcDd42c69d742De1dC5B49",
    StakedBlpTracker: "0xD60b526164ca64Fe18008635B00d7906754a28b0",

    StakedBsxDistributor: "0x7cF71cB56237FcbA112DE57288580A4794DB4a51",
    StakedBlpDistributor: "0x833576194905bEbE5E83519BF455aD9687faBdDA",

    BsxVester: "0xAEb31B5c098fe430Bd866a26e57c2082e16AB5d4",
    BlpVester: "0xDC6E23722D9620Ab41122206076Be4362edc22B2",

    OrderBook: "0x5EF876316C6c4AF00e74A5E53f94Fc2A0e72574F",
    OrderBookReader: "0x9231436F75e144dD6074c62DF714e5599F85FCd1",

    PositionRouter: "0xa9a36fdA1619a8B9A1e4a16ABa71ef006d678f17",
    PositionManager: "0x4D365963ac0d7c6Be5cF466303Bb98d12d754Ed8",
    // PositionManager: "0x854b2Ee4E60f5135FE431864372f194432363F56",

    // UniswapBsxEthPool: "",
    ReferralStorage: "0xb5d6886515F5B55381aBb08e59cDfb491757d917",
    ReferralReader: "0x0Da39af06F7f7Be2b3e74f57611AC2D85A1339B0",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    UniswapBsxEthPool: "0x9fa7166e8e3D036BF5aFB4AEC252882b3CDF43A7",

    //Faucet Tokens
    FBTC: "0x41E26e1285D9F7950d6371cEe2CC45e4F178C9d7",
    FETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    FUSDT: "0xdA330C3Eb4E177691E1f6c105Fb01df27dA4AB27",
    FUSDC: "0x3076A30D0Ea899508737961e1926f925689f5c95",
  },

  [BASE_GOERLI]: {
    // base goerli
    Vault: "0x32cAb06705Bf240155a0637D0FdF77E9084B17DD",
    Router: "0x124C59b3C32bBe3a57B82f382058D1b520b1cf57",
    VaultReader: "0x921A6C18EDd5537564373D5795409285d9a70e56",
    Reader: "0x7543BaB98465529BBBcd0ccAe61ac47BEb4fbE21",
    BlpManager: "0x6b2B3174FdB5E282AdE3dDD736328C9c303A8E41",
    RewardRouter: "0x77b4963A2fB2cf9b4Dd4aaA3B2BED839688738c9",
    BlpRewardRouter: "0x6d834011Ad3e4CB0A47Fc1452B2d3D2ebB321E01",
    RewardReader: "0x881e2aC78DF5879d1C381Fc7E27F4D5e4443Ffa5",
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BLP: "0x2fBd059CCbFf9bF8127D3409AB21E083E217fbd4",
    BSX: "0xF5920D3b7fc06eaCAEC27C9B1516C41FF16fB8a9",
    ES_BSX: "0x638a55e936AbE045eAc18209f0479aafB037A719",
    BN_BSX: "0x7F4C47d84F62897ea99e19Df1CBB603317d52b2D",
    USDM: "0xaD4abAF0C23aEc651c95865F14A0b9faEfc04F9E",

    StakedBsxTracker: "0x05635d66d9C110E51d00388f955b44877b3d3679",
    BonusBsxTracker: "0x0D6eB58c6798f5211421778649BeE330c1065d88",
    FeeBsxTracker: "0x18731B2911571B00573F0bc97C393F9D6C4dCb80",

    FeeBlpTracker: "0x57E5B2C04E106a907EbcDd42c69d742De1dC5B49",
    StakedBlpTracker: "0xD60b526164ca64Fe18008635B00d7906754a28b0",

    StakedBsxDistributor: "0x7cF71cB56237FcbA112DE57288580A4794DB4a51",
    StakedBlpDistributor: "0x833576194905bEbE5E83519BF455aD9687faBdDA",

    BsxVester: "0xAEb31B5c098fe430Bd866a26e57c2082e16AB5d4",
    BlpVester: "0xDC6E23722D9620Ab41122206076Be4362edc22B2",

    OrderBook: "0x5EF876316C6c4AF00e74A5E53f94Fc2A0e72574F",
    OrderBookReader: "0x9231436F75e144dD6074c62DF714e5599F85FCd1",

    PositionRouter: "0xa9a36fdA1619a8B9A1e4a16ABa71ef006d678f17",
    PositionManager: "0x4D365963ac0d7c6Be5cF466303Bb98d12d754Ed8",
    // PositionManager: "0x854b2Ee4E60f5135FE431864372f194432363F56",

    // UniswapBsxEthPool: "",
    ReferralStorage: "0xb5d6886515F5B55381aBb08e59cDfb491757d917",
    ReferralReader: "0x0Da39af06F7f7Be2b3e74f57611AC2D85A1339B0",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    UniswapBsxEthPool: "0x9fa7166e8e3D036BF5aFB4AEC252882b3CDF43A7",

    //Faucet Tokens
    FBTC: "0x41E26e1285D9F7950d6371cEe2CC45e4F178C9d7",
    FETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    FUSDT: "0xdA330C3Eb4E177691E1f6c105Fb01df27dA4AB27",
    FUSDC: "0x3076A30D0Ea899508737961e1926f925689f5c95",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
